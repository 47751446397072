<template>
  <div>
    <v-navigation-drawer
      ref="chatDrawer"
      v-model="visible"
      app
      right
      disable-resize-watcher
      temporary
      width="350"
      @input="onDrawerChange"
    >
      <template v-slot:prepend>
        <v-toolbar color="primary" height="73" flat dark>
          <v-btn icon @click="visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Chat </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="fill-height align-center">
            <SessionChatAttachment
              v-if="enableAttachments"
              @uploaded="sendMessage"
            />
          </v-toolbar-items>
        </v-toolbar>
      </template>

      <v-card v-if="!messages.length" tile>
        <v-card-text> Non ci sono ancora messaggi. Inviane uno! </v-card-text>
      </v-card>

      <v-list v-chat-scroll shaped rounded>
        <SessionChatRow
          v-for="message in mappedMessages"
          :key="message.ts"
          :message="message"
          :sender="sender"
          :last_read="last_read"
        ></SessionChatRow>
      </v-list>

      <template v-slot:append>
        <v-form autocomplete="off" @submit.prevent="() => sendMessage(text)">
          <input
            autocomplete="nonrompere"
            name="hidden"
            type="text"
            style="display: none"
          />
          <v-row
            dense
            class="pa-2 mb-3"
            align="end"
            justify="center"
            no-gutters
          >
            <v-col style="overflow-x: hidden">
              <v-textarea
                v-model="text"
                auto-grow
                rows="1"
                style="max-height: 100px; width: 100%"
                placeholder="Inserisci testo"
                @keydown.enter="sendByEnter"
              >
              </v-textarea>
            </v-col>
            <v-col cols="2" class="text-center">
              <v-btn
                class="mb-3"
                type="submit"
                rounded
                icon
                color="primary"
                :disabled="!messageCanBeSent(text)"
              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </v-navigation-drawer>

    <v-fab-transition>
      <v-btn
        fab
        fixed
        bottom
        right
        color="primary"
        elevation="0"
        style="z-index: 100"
        @click="visible = !visible"
      >
        <v-badge :content="unreadCount" :value="unreadCount > 0" color="red">
          <v-icon>mdi-message</v-icon>
        </v-badge>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import SessionChatRow from '@/src/components/sessions/chat/SessionChatRow.vue'
import SessionChatAttachment from '@/src/components/sessions/chat/SessionChatAttachment.vue'
import { EventBus, EventsType } from '@services/eventbus.js'
import { netRequest } from '@api/client.js'
import {
  getFromSessionStorage,
  isOwn,
  signal,
  getSessionStorage,
  addToSessionStorage,
} from '@/src/utils/session'
import { overEvery, flow, trim, size, property } from 'lodash'

export default {
  name: 'SessionChat',
  components: {
    SessionChatAttachment,
    SessionChatRow,
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    sender: {
      type: String,
      required: true,
    },
    enableAttachments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      text: '',
      last_read: 0,
      messages: [],
      notificationSettings: {
        position: 'top-right',
        timeout: 5000,
      },
    }
  },
  computed: {
    unreadCount() {
      return this.messages.filter((m) => m.ts > this.last_read).length
    },
    drawerIsOpen() {
      return !!this.visible
    },
    mappedMessages() {
      return this.messages.map(this.messageParser)
    },
  },

  created() {
    document.addEventListener('backbutton', this.onBackButtonPressed, false)
    EventBus.$on(EventsType.SEND_CHAT_IMAGE, this.onSendImage)
  },
  mounted() {
    // Retrieve or create a cache for messages
    const storage = getSessionStorage(this.session)
    // Start with reading messages from the cache
    this.messages = storage.messages
    if (storage.last_read) {
      this.last_read = storage.last_read
    }

    this.session.on('signal:event', this.onEvent)
    this.session.on('signal:chat', this.onChatEvent)
  },
  beforeDestroy() {
    document.removeEventListener('backbutton', this.onBackButtonPressed)
    EventBus.$off(EventsType.SEND_CHAT_IMAGE)
  },
  methods: {
    /**
     * If the back button is pressed when the
     * drawer is open, close the drawer
     */
    onBackButtonPressed(e) {
      e.preventDefault()
      if (this.drawerIsOpen) {
        e.preventDefault()
        this.visible = false
      }
    },

    /**
     * Handles all received events
     */
    onEvent(event) {
      const message = this.getEventData(event)
      this.addAddMessageToCache(message)
      this.readCache()
      return message
    },

    /**
     * Perform additional operations when a chat event occurs.
     */
    onChatEvent(event) {
      const message = this.onEvent(event)
      if (!this.visible) {
        if (this.isNotOwnEvent(event)) {
          this.playRingTone()
          if (this.hasAttachments(message)) {
            this.notifyAttachments()
          }
        }
      } else this.updateLastRead()

      this.scrollToBottom()
    },

    /**
     * Crates a timestamp after which all msgs are considered unread
     */
    updateLastRead() {
      this.last_read = new Date().getTime()
      addToSessionStorage(this.session, 'last_read', this.last_read)
    },

    /**
     * Adds a message to the stored cache of messages
     */
    addAddMessageToCache(message) {
      addToSessionStorage(this.session, 'messages', [...this.messages, message])
      return message
    },

    /**
     * Reads the cache of messages and saves it
     */
    readCache() {
      const messages = getFromSessionStorage(this.session, 'messages')
      this.messages = messages
    },

    onDrawerChange(open) {
      // When closing the drawer every new message will be counted as unread.
      if (!open) this.updateLastRead()
      else this.scrollToBottom()
    },

    scrollToBottom() {
      const node = this.$refs.chatDrawer?._vnode?.children?.find(
        (c) => c.data.staticClass === 'v-navigation-drawer__content'
      )

      if (!node) return

      this.$nextTick(() => {
        node.elm.scrollTop = node.elm.scrollHeight
      })
    },

    /** Extracts the relevant parts of an event */
    getEventData: flow([property('data'), JSON.parse]),

    /** Ensure the emssage is not all spaces */
    messageCanBeSent: overEvery([flow([trim, size, Boolean])]),

    /**
     * When Enter is pressed with the shift key do nothing.
     * Otherwise send the message.
     */
    sendByEnter({ shiftKey }) {
      if (!shiftKey) {
        if (this.messageCanBeSent(this.text)) {
          this.sendMessage(this.text)
        }
      }
    },

    /**
     * Given any type of content maps it to a type of message.
     * When the type of message has been found, generate a message obj.
     * Then sends the message obj.
     */
    async sendMessage(content) {
      const type = this.typeOfContent(content)
      const message = this.generateMessageFromContent(type)(content)
      await this.signal('chat', message)
      this.text = ''
    },

    typeOfContent(content) {
      if (content?.mimetype?.includes('image')) return 'image'
      else if (content.mimetype) return 'file'
      else return 'text'
    },

    generateMessageFromContent(type) {
      return (content) => {
        switch (type) {
          case 'text':
            return { type, text: this.parseText(content) }
          case 'image':
            return {
              type,
              src: `/api/quicksupport/attachment/${content.filename}`,
            }
          case 'file':
            return {
              ...content,
              type,
              href: `/api/quicksupport/attachment/${content.filename}`,
            }
        }
      }
    },

    async onSendImage(image) {
      this.visible = true

      const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n) {
          u8arr[n - 1] = bstr.charCodeAt(n - 1)
          n -= 1 // to make eslint happy
        }
        return new File([u8arr], filename, { type: mime })
      }

      const attachment = dataURLtoFile(image)
      const data = new FormData()
      data.append('attachment', attachment, attachment.name)

      const { file } = await netRequest(
        'POST',
        `../quicksupport/attachment/${this.session.id}`,
        data
      )

      this.sendMessage({
        filename: file.filename,
        mimetype: file.mimetype,
        originalname: file.originalname,
        size: file.size,
      })
    },

    async signal(type, data) {
      try {
        return signal(this.session, type, data, {
          beforeSend: this.addMetadata,
        })
      } catch (error) {
        this.$dialog.notify.warning(
          'Impossibile inviare i dati: disconnesso dal server.',
          this.notificationSettings
        )
      }
    },
    addMetadata(data) {
      return { ...data, ts: new Date().getTime(), sender: this.sender }
    },

    isOwnEvent(event) {
      return isOwn(this.session, event)
    },
    isNotOwnEvent(event) {
      return !this.isOwnEvent(event)
    },

    hasAttachments: (message) => ['image', 'file'].includes(message.type),

    notifyAttachments() {
      this.$dialog.notify.info(
        'Hai ricevuto un allegato. Apri la chat per vederlo.',
        this.notificationSettings
      )
    },

    playRingTone() {
      const audio = new Audio('/static/juntos.mp3')
      audio.play()
    },

    parseText: flow([trim]),

    getCommonProps(message) {
      const { sender, ts } = message
      const isOwn = sender === this.sender
      const alignment = isOwn ? 'right' : 'left'
      const color = isOwn ? 'white' : 'white'
      const read = this.last_read >= ts
      return {
        alignment,
        color,
        isOwn,
        read,
        sender,
        ts,
      }
    },

    messageParser(message) {
      switch (this.getMessageType(message)) {
        case 'event':
          return this.enrichMessage(message, { type: 'event' })
        case 'file':
        case 'image':
        case 'text':
          return this.enrichMessage(message)
      }
    },
    getMessageType: (message) => message.type || 'event',
    enrichMessage(message, additionalProps = {}) {
      return {
        ...this.getCommonProps(message),
        ...additionalProps,
        ...message,
      }
    },
  },
}
</script>

<style scoped></style>
