<template>
  <v-list-item-title v-linkified>
    {{ message.text }}
  </v-list-item-title>
</template>

<script>
export default {
  name: 'EventMessage',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>
</style>
