<template>
  <v-btn text block small @click="saveAsFile">
    <v-icon left>mdi-download</v-icon>
    Salva
  </v-btn>
</template>

<script>
import FileSaver from 'file-saver'

export default {
  name: 'DownloadButton',
  props: {
    url: {
      type: String,
      required: true,
    },
    filename: {
      type: String,
      required: false,
    },
  },
  computed: {
    computedFileName() {
      return this.filename || this.url.substring(this.url.lastIndexOf('/') + 1)
    },
  },
  methods: {
    saveAsFile() {
      FileSaver.saveAs(this.url, this.computedFileName)
    },
  },
}
</script>

<style></style>
