<template>
  <div
    ref="subscriber"
    :class="[
      'subscriber-video fill-height',
      { priority: stream.videoSource === 'screen' },
    ]"
    @dblclick.stop="() => fullscreen('subscriber')"
  ></div>
</template>

<script>
import OT from '@opentok/client'
import { createSubscriber } from '@/src/utils/session'
import screenfull from 'screenfull'

export default {
  name: 'SubscriberVideo',
  props: {
    session: {
      type: OT.Session,
      required: true,
    },
    stream: {
      type: OT.Stream,
      required: true,
    },
  },
  data() {
    return {
      subscriber: null,
    }
  },
  async mounted() {
    this.$emit('beforeCreate')
    this.subscriber = await createSubscriber(
      this.session,
      this.stream,
      this.$refs.subscriber
    )
    this.$emit('afterCreate')
  },
  methods: {
    fullscreen(target) {
      if (screenfull.isEnabled) {
        screenfull.request(this.$refs[target])
      }
    },
  },
}
</script>

<style lang="scss">
.subscriber-video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
</style>
