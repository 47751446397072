import Vue from 'vue'

export const EventBus = new Vue()

export const EventsType = Object.freeze({
  REQUEST_FRAME: 'REQUEST_FRAME',
  IMG_DATA: 'IMG_DATA',
  ERR_NO_VIDEO: 'ERR_NO_VIDEO',
  SEND_CHAT_IMAGE: 'SEND_CHAT_IMAGE',
  SEND_QUERY: 'SEND_QUERY',
  SEND_QUERY_DISABLE: 'SEND_QUERY_DISABLE',
})
