<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-subtitle :class="headerClass">
        <v-badge
          color="red"
          dot
          inline
          :left="message.isOwn"
          :value="!message.isOwn && !message.read"
        >
          {{ message.sender }} - {{ message.ts | moment('HH:mm:ss') }}
        </v-badge>
      </v-list-item-subtitle>
      <component :is="is" :message="message" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import TextMessage from '@components/sessions/chat/TextMessage.vue'
import EventMessage from '@components/sessions/chat/EventMessage.vue'
import ImageMessage from '@components/sessions/chat/ImageMessage.vue'
import FileMessage from '@components/sessions/chat/FileMessage.vue'
export default {
  name: 'SessionChatRow',
  components: { TextMessage, EventMessage, ImageMessage, FileMessage },
  props: {
    message: {
      type: Object,
      required: true,
    },
    sender: { type: String, required: true },
    last_read: { type: Number },
  },
  computed: {
    headerClass() {
      return {
        'text-right': this.message.isOwn,
      }
    },
    is() {
      const map = {
        event: EventMessage,
        file: FileMessage,
        image: ImageMessage,
        text: TextMessage,
      }
      return map[this.message.type]
    },
  },
}
</script>

<style scoped>
.message {
  border-radius: 10px;
  border: 0.5px solid lightgrey;
  padding: 5px;
}
</style>
