<template>
  <v-list-item-content>
    <v-img :src="message.src" :lazy-src="message.src" contain>
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <DownloadButton :url="message.src" />
  </v-list-item-content>
</template>

<script>
import DownloadButton from '@components/sessions/chat/DownloadButton.vue'
export default {
  name: 'ImageMessage',
  components: {
    DownloadButton,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>
</style>
