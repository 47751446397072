<template>
  <v-list-item-title v-linkified :class="classes">
    <span :style="styles" class="message">{{ message.text }} </span>
  </v-list-item-title>
</template>

<script>
export default {
  name: 'TextMessage',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    classes() {
      const classes = ['d-flex', { 'justify-end': this.message.isOwn }]
      return classes
    },
    styles() {
      const styles = {
        // Maintain newlines but also break text that is too long
        'white-space': 'pre-wrap',
        'max-width': this.message.isOwn ? '85%' : 'auto',
      }
      return styles
    },
  },
}
</script>

<style>
.message {
  border-radius: 10px;
  border: 0.5px solid lightgrey;
  padding: 5px;
}
</style>
