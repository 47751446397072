/* eslint-disable */
import {
  initSession,
  initPublisher,
  Event,
  Session,
  Stream,
} from '@opentok/client'

import { curry, identity, cloneDeep, iteratee, negate, flow } from 'lodash'
import {
  set as LSSet,
  getOrSet as LSGetOrSet,
  removeKey as LSRemoveKey,
} from '@utils/storage'
import { filter, ifElse } from '@utils/functions'

export const initialConfig = {
  agent: {
    /** Private props should not be used, use a getter */
    _status: {
      audio: false,
      blur: false,
      connected: true,
      fake_bg: false,
      mute: false,
      permissions: true,
      publishing: false,
      screen: false,
      video: false,
    },
    _options: {
      camera: {
        // When set to null or false will not even ask for permission.
        // Leave undef for default behaviour
        audioSource: undefined,
        facingMode: 'user',
        frameRate: 15,
        height: '100%',
        mirror: true,
        name: 'Agente',
        // Publish audio on start
        publishAudio: false,
        // Publish video on start
        publishVideo: false,
        resolution: '1280x720',
        showControls: false,
        // When set to null or false will not even ask for permission.
        // Leave undef for default behaviour
        videoSource: undefined,
        width: '100%',
      },
      screen: {
        name: 'Schermo',
        width: '100%',
        height: '100%',
        showControls: false,
        maxResolution: { width: 1920, height: 1080 },
        videoSource: 'screen',
        // audioSource: this.publisher.getAudioSource(),
        insertMode: 'append',
      },
    },
    /** Get a copy of the default status */
    getDefaultStatus: () => cloneDeep(initialConfig.agent._status),

    /** Get a copy of the default options */
    getDefaultOptions: (publisherType) =>
      cloneDeep(initialConfig.agent._options[publisherType]),

    /**
     * Provided with settings, this function will create
     *  an initial status that is coherent
     */
    getStatusBySettings: (settings = {}) => {
      const {
        agent_video,
        agent_video_autostart,
        /* agent_video_effects, */
        agent_audio,
        agent_audio_autostart,
      } = settings

      const defaultStatus = initialConfig.agent.getDefaultStatus()
      // Video is enabled straight away only if allowed and autostart
      if (agent_video) {
        defaultStatus.video = agent_video_autostart
        // Audio is enabled straight away only if allowed and autostart
      }
      if (agent_audio) {
        defaultStatus.audio = agent_audio_autostart
      }
      return defaultStatus
    },

    /**
     * Provided with session settings this function will return
     * options that can be passed to a publisher
     * that are coherent for the camera
     */
    getOptionsBySettingsForCamera: (settings = {}) => {
      const {
        agent_video,
        agent_video_autostart,
        agent_audio,
        agent_audio_autostart,
      } = settings
      const defaultOpts = initialConfig.agent.getDefaultOptions('camera')
      // If video is not enabled don't even ask for permission (as it does by default)
      if (!agent_video) {
        defaultOpts.videoSource = null
      } else {
        // If video is enabled set whether it auto starts
        defaultOpts.publishVideo = agent_video_autostart
      }
      // If audio is not enabled don't even ask for permission (as it does by default)
      if (!agent_audio) {
        defaultOpts.audioSource = null
      } else {
        // If audio is enabled set whether it auto starts
        defaultOpts.publishAudio = agent_audio_autostart
      }
      return defaultOpts
    },
  },
  customer: {
    /** Private props should not be used, use a getter */
    _status: {
      audio: false,
      blur: false,
      connected: true,
      fake_bg: false,
      mute: false,
      permissions: true,
      publishing: false,
      screen: false,
      video: false,
    },
    _options: {
      camera: {
        // When set to null or false will not even ask for permission.
        // Leave undef for default behaviour
        audioSource: undefined,

        // On mobile, load the rear camera first
        facingMode: 'environment',
        height: '100%',
        mirror: false,
        name: 'Cliente',
        // Publish audio on start
        publishAudio: false,
        // Publish video on start
        publishVideo: false,
        // Shows the OT built in controls
        showControls: false,
        // When set to null or false will not even ask for permission.
        // Leave undef for default behaviour
        videoSource: undefined,
        width: '100%',
      },
      screen: {
        width: '100%',
        height: '100%',
        showControls: false,
        maxResolution: { width: 1920, height: 1080 },
        videoSource: 'screen',
        // When set to null or false will not even ask for permission.
        // Leave undef for default behaviour
        audioSource: undefined,
        insertMode: 'append',
      },
    },
    /** Get a copy of the default status */
    getDefaultStatus: () => cloneDeep(initialConfig.customer._status),
    /** Get a copy of the default options */
    getDefaultOptions: (publisherType) =>
      cloneDeep(initialConfig.customer._options[publisherType]),
    /**
     * Provided with settings, this function will create
     *  an initial status that is coherent
     */
    getStatusBySettings: (settings = {}) => {
      const {
        customer_video,
        customer_video_autostart,
        customer_audio,
        customer_audio_autostart,
      } = settings

      const defaultStatus = initialConfig.customer.getDefaultStatus()
      // Video is enabled straight away only if allowed and autostart
      if (customer_video) {
        defaultStatus.video = customer_video_autostart
        // Audio is enabled straight away only if allowed and autostart
      }
      if (customer_audio) {
        defaultStatus.audio = customer_audio_autostart
      }
      return defaultStatus
    },
    /**
     * Provided with session settings this function will return
     * options that can be passed to a publisher
     * that are coherent for the camera
     */
    getOptionsBySettingsForCamera: (settings = {}) => {
      const {
        customer_video,
        customer_video_autostart,
        customer_audio,
        customer_audio_autostart,
      } = settings
      const defaultOpts = initialConfig.customer.getDefaultOptions('camera')
      // If video is not enabled don't even ask for permission (as it does by default)
      if (!customer_video) {
        defaultOpts.videoSource = null
      } else {
        // If video is enabled set whether it auto starts
        defaultOpts.publishVideo = customer_video_autostart
      }
      // If audio is not enabled don't even ask for permission (as it does by default)
      if (!customer_audio) {
        defaultOpts.audioSource = null
      } else {
        // If audio is enabled set whether it auto starts
        defaultOpts.publishAudio = customer_audio_autostart
      }
      return defaultOpts
    },
    /**
     * Provided with session settings this function will return
     * options that can be passed to a publisher
     * that are coherent for screen sharing
     */
    getOptionsBySettingsForScreen: (settings) => {
      const defaultOpts = initialConfig.customer.getDefaultOptions('screen')
      return defaultOpts
    },
  },
}

/**
 * Takes a function that would take a node compatible callback (err, data) => ...
 * and renders it a Promise.
 * @param {Function} fn
 * @returns
 */
export const promiseFromCallback = (fn) =>
  new Promise((resolve, reject) => {
    fn((err, data) => {
      if (err) reject(err)
      resolve(data)
    })
  })

/**
 * Creates a session and returns it.
 * @returns Promise<Session | Error>
 */
export const createSession = (api_key, session_id) =>
  new Promise((resolve, reject) => {
    try {
      const session = initSession(api_key, session_id)
      resolve(session)
    } catch (error) {
      reject(error)
    }
  })

/**
 * Creates a publisher that points to a DOM element ID and returns it.
 * @returns Promise<Publisher | Error>
 */
export const createPublisher = curry(
  (
    elementId,
    options = {
      insertMode: 'append',
      width: '300px',
      height: '300px',
      resolution: '1280x720',
      frameRate: 15,
    }
  ) =>
    new Promise((resolve, reject) => {
      const publisher = initPublisher(
        elementId,
        options,
        ifElse(identity, reject, () => resolve(publisher))
      )
    })
)

/**
 * Subscribes to the session, and displays the created video in the passed element.
 * @returns Promise<Suscriber | Error>
 */
export const createSubscriber = curry(
  (
    session,
    stream,
    elementId,
    options = initialConfig.agent.getDefaultOptions('camera')
  ) =>
    new Promise((resolve, reject) => {
      const subscriber = session.subscribe(
        stream,
        elementId,
        options,
        ifElse(identity, reject, () => resolve(subscriber))
      )
    })
)

/**
 * Connects a session using a token and returns the session
 * @returns Promise<Session | Error>
 */
export const connectSession = (token, session) =>
  new Promise((resolve, reject) =>
    session.connect(
      token,
      ifElse(identity, reject, () => resolve(session))
    )
  )

/**
 * Passed a parameter understands how to extract a connection from it.
 */
export const getConnection = (element) => {
  const pairs = [
    [Event, 'from'],
    [Session, 'connection'],
    [Stream, 'connection'],
  ]
  for (const [className, propName] of pairs) {
    if (element instanceof className) return element[propName]
  }
}

/**
 * Given a session exists, sends your stream to a session.
 * If you start an unpublished video, only you will be able to see it.
 * @returns Promise<Session | Error>
 */
export const joinSession = curry(
  (session, publisher) =>
    new Promise((resolve, reject) =>
      session.publish(
        publisher,
        ifElse(identity, reject, () => resolve(session))
      )
    )
)

/**
 * Disconnect a connection from the session.
 * Returns true on completion without error.
 */
export const disconnect = curry(
  (session, connection) =>
    new Promise((resolve, reject) => {
      session.disconnect(
        connection,
        ifElse(identity, (err) => reject(err), resolve(true))
      )
    })
)

/**
 * Given a session exists, remove your stream to a session.
 * @returns Promise<Session | Error>
 */
export const leaveSession = (session, publisher) => session.unpublish(publisher)

/**
 * Given a session exists forces the publisher of the stream to disconnect
 * @returns Promise <Session | Error>
 */
export const forceLeaveSession = (session, stream) =>
  new Promise((resolve, reject) => {
    session.forceUnpublish(
      stream,
      ifElse(identity, reject, () => resolve(session))
    )
  })

/**
 * Senda a signal through the session.
 * Use beforeSend to enrich data before stringifying and sending them off.
 * @returns Promise<Event | Error>
 */
export const signal = curry(
  (session, type, data = {}, options = { beforeSend: identity }) => {
    const { beforeSend } = options
    const stringified = (event) => ({
      ...event,
      data: JSON.stringify(event.data),
    })
    return new Promise((resolve, reject) => {
      const event = {
        type,
        data: beforeSend(data),
      }
      session.signal(
        stringified(event),
        ifElse(identity, reject, () => resolve(event))
      )
    })
  }
)

/**
 * Understands if the event was created by the same connection that is receiving it.
 * In short understands if the event was emitted by you.
 */
export const isOwn = curry((session, event) => {
  const sessionConnection = getConnection(session)
  const eventConnection = getConnection(event)
  return sessionConnection.id === eventConnection.id
})

/**
 * Given a session extracts the streams that it contains.
 * useful on init for extracting every other stream and sub to them
 */
export const getStreamsInSession = (session) => {
  const final = []
  session.streams.forEach((stream) => {
    console.log('Stream is destroyed', stream, stream.destroyed)
    if (!stream.destroyed) final.push(stream)
  })
  return final
}

/**
 * Understands if the stream is a visible stream (if it has video)
 */
export const isVisible = iteratee({ hasVideo: true })

/**
 * Used to understand if the stream is a publisher stream.
 * Basically understands if this stream is yours.
 */

export const isPublisherStream = (stream) => !!stream.publisher

/**
 * Used to understand if the passed stream is a subscriber.
 * Subscriber streams do not have publisher property.
 */

export const isSubscriberStream = negate(isPublisherStream)

/**
 * Passed a session will extract the streams that are NOT yours.
 * Basically all the streams you have DO NOT have a publisher for.
 */
export const getForeignStreams = flow([
  getStreamsInSession,
  filter(isSubscriberStream),
])

const emptySessionStorage = {
  messages: [],
}

/**
 * Given a session will create a key in localStorage for the session.
 */
export const createSessionStorage = (session) => {
  LSSet(['sessions', session.id], emptySessionStorage)
}

/**
 * Retrieves data linked to the session from localStorage
 */
export const getSessionStorage = (session) => {
  return LSGetOrSet(['sessions', session.id], emptySessionStorage)
}

/**
 * Removes data related to the session from localStorage
 */
export const clearSessionStorage = (session) => {
  return LSRemoveKey(['sessions', session.id])
}

/**
 * Adds a key to the session localStorage.
 * If the session storage doesn't exist it will create it and add the key.
 */
export const addToSessionStorage = curry((session, key, data) => {
  return LSSet(['sessions', session.id, key], data)
})

/**
 * Removes a key from a session storage, if it exists.
 */
export const removeFromSessionStorage = curry((session, ...keys) =>
  LSRemoveKey(['sessions', session.id, ...keys])
)

/**
 * Get a key from the session storage. If the key doesn't exist it will create it.
 */
export const getFromSessionStorage = (session, keys, defaultTo) => {
  return LSGetOrSet(['sessions', session.id, [].concat(keys)], defaultTo)
}
