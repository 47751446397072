<template>
  <div>
    <v-list-item-title :class="{ 'text-right': message.isOwn }">
      Ha condiviso un file.
    </v-list-item-title>
    <v-list-item-content>
      <a :href="message.href" target="_blank" class="text-center">{{
        message.originalname
      }}</a>
      <DownloadButton :url="message.href" :file-name="message.originalname" />
    </v-list-item-content>
  </div>
</template>

<script>
import DownloadButton from '@components/sessions/chat/DownloadButton.vue'
export default {
  name: 'FileMessage',
  components: { DownloadButton },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style></style>
