<template>
  <v-fade-transition>
    <v-file-input
      v-if="!loading"
      v-model="selected"
      hide-input
      @change="onNewFile"
    />
    <v-progress-circular v-else :value="progress" size="42">
      <span style="font-size: 11px;">{{ progress }}%</span>
    </v-progress-circular>
  </v-fade-transition>
</template>

<script>
import { mapState } from 'vuex'
import { netRequest } from '@api/client.js'

export default {
  name: 'SessionChatAttachment',
  data: () => ({
    allowed: [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/gif',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'text/csv',
      'text/plain',
    ],
    upload: false,
    loading: false,
    progress: 0,
    selected: null,
    files: [],
  }),
  computed: {
    ...mapState('sessions', {
      session: 'current',
    }),
  },
  methods: {
    getFileEntry(fileURL) {
      return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL(
          fileURL,
          (fileEntry) => {
            resolve(fileEntry)
          },
          (err) => {
            reject(err)
          }
        )
      })
    },
    getFileContentAsBlob(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () =>
          resolve(
            new Blob([reader.result], {
              type: file.type,
              name: file.name,
            })
          )
        reader.onerror = (error) => reject(error)
        reader.readAsArrayBuffer(file)
      })
    },

    async buildFormData(item) {
      const data = new FormData()

      if (item.fromCamera) {
        let content = null
        content = await this.getFileContentAsBlob(item.file)
        data.append('attachment', content, item.name)
      } else {
        data.append('attachment', item.file)
      }

      return data
    },

    onNewFile(file) {
      // validazione
      if (!this.allowed.includes(file.type)) {
        this.$dialog.notify.warning(
          'Il file selezionato non è in formato accettato.'
        )
        return
      }

      if (file.size >= 10 * 1024 * 1024) {
        this.$dialog.notify.warning(
          'La dimensione dei file supera il massimo consentito di 10Mb.'
        )
        return
      }

      if (this.files.length >= 10) {
        this.$dialog.notify.warning(
          "E' stato raggiunto il numero massimo di files selezionabili."
        )
        return
      }

      if (
        this.files.find((f) => f.name === file.name && f.size === file.size)
      ) {
        this.$dialog.notify.warning(
          'Il file selezionato è già presente in lista.'
        )
        return
      }

      this.file = {
        name: file.name,
        path: file.localURL || window.URL.createObjectURL(file),
        fromCamera: !!file.localURL,
        type: file.type,
        size: file.size,
        file,
        progress: 0,
      }

      return this.startUpload()
    },

    async startUpload() {
      try {
        this.loading = true

        const { file } = await netRequest(
          'POST',
          `../quicksupport/attachment/${this.session.id}`,
          await this.buildFormData(this.file),
          {
            timeout: 60 * 3 * 1000,
            onUploadProgress: (progressEvent) => {
              this.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
            },
          }
        )

        this.$emit('uploaded', {
          filename: file.filename,
          mimetype: file.mimetype,
          originalname: file.originalname,
          size: file.size,
        })
      } catch (err) {
        console.warn(err)
        await this.$dialog.notify.warning(
          'Si è verificato un errore durante il caricamento. Verificare la propria connessione.'
        )
      } finally {
        this.loading = false
        this.files = []
        this.selected = null
        this.progress = 0
      }
    },
  },
}
</script>

<style scoped></style>
